import { Image, Button, Tag, NavBar, Icon, Empty, Toast, Rate,Popup  } from 'vant';
import apis from '../utils/apis'

export default {
  name: 'skiEvaluate',
  components: {
    'van-image': Image,
    'van-button': Button,
    'van-tag': Tag,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-empty': Empty,
    'van-rate': Rate,
    'van-popup': Popup ,
  },
  data () {
    return {
      info: {},//信息
      orderDetails:[],
      shopPoint: 0,
      servicePoint: 0,
      commodityPoint: 0,
      showSuccess:false,
    }
  },
  methods: {
    getInfo () {
      let me = this;
      this.$get3({
        url: apis.findOrder + this.$route.query.orderId,
      }).then(res => {
        console.log(res)
        me.info = res.data;
        if(res.data.orderDetails.length>0){
          me.orderDetails = res.data.orderDetails[0];
        }
        
      });
    },
    evaluate () {
      let me = this;
      if (!me.orderDetails.commodity.commodityId) return Toast('未获取到订单信息，请回到列表页重新点击进行评价~');
      if (!me.info.order.orderNumber) return Toast('未获取到订单信息，请回到列表页重新点击进行评价~');
      me.$post2({
        url: apis.postUserPoint,
        params: {
          "commodityId": me.orderDetails.commodity.commodityId,
          "commodityPoint": me.commodityPoint,
          "orderNumber": me.info.order.orderNumber,
          "servicePoint": me.servicePoint,
          "shopPoint": me.shopPoint,
          "token": me.$global.token
        },
      }).then(res => {
        if(res.status){
          me.openSuccess()
        }else{
          Toast(res.message);
        }
      }).catch(err => {
        Toast(err.message);

      });
    },
    openSuccess () {
      this.showSuccess = true
    },
    golist(){
      setTimeout(() => {
        this.back()
      }, 3000);
    },
    back () {
      this.$router.go(-1)
    },
  },
  created () {
    this.getInfo()
  },
  mounted () {
  },
}