<template>
  <div class="full">
    <van-nav-bar title="评价" @click-left="back()">
      <template #left>
        <van-icon color="#fff" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="info" v-if="orderDetails.commodity">
        <img :src="orderDetails.commodity.cover" alt="">
        <h2>{{orderDetails.commodity.name}}</h2>
      </div>
      <div class="eva">
        <h6>- 总体评分 -</h6>
        <div class="table">
          <div class="box">
            <span>购物体验</span>
            
            <van-rate  color="#FF9805"  v-model="shopPoint" />
          </div>
          <div class="box">
            <span>服务态度</span>
            <van-rate color="#FF9805" v-model="servicePoint" />
          </div>
          <div class="box">
            <span>产品质量</span>
            <van-rate color="#FF9805" v-model="commodityPoint" />
          </div>
        </div>
      </div>
      <div class="btnBox">
        <van-button round class="btn" color="#3FD467" @click="evaluate()">提交</van-button>
      </div>
    </div>
    <van-popup v-model:show="showSuccess">
    <div class="success" >
      <p>感谢您的评价</p>
      <p>即将返回订单列表</p>
      <van-button round class="btn" color="#FFCD2F"  @click="back()">我知道了</van-button>

    </div>
    </van-popup>
  </div>
</template>
<script src='./skiEvaluate.js'></script>
<style lang="scss" scoped src='./skiEvaluate.scss'></style>
